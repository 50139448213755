import 'core-js/proposals/reflect-metadata';
import 'zone.js';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'font-awesome/css/font-awesome.css';
import 'bootstrap-legacy/dist/css/bootstrap.min.css';

import '../vendor/jquery-noty/jquery.noty.css';
import '../vendor/jquery-noty/noty_theme_default.css';
import '../vendor/chosen/chosen.min.css';
import '../vendor/simpliq-theme/style.custom.css';

import '../styles/main.css';
import '../styles/bookings.css';
import '../styles/components/_buttons.css';
import '../styles/components/_colors.css';
import '../styles/components/_flaticons.css';
import '../styles/components/_grids.css';
import '../styles/components/_overwrites.css';
import '../styles/components/_spacing.css';

import '../styles/scss/app-lite/shared.scss';

import './ajs.module';

import { AppModule } from './app.module';

platformBrowserDynamic().bootstrapModule(AppModule);